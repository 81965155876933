var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"white"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.filter(function (x) { return x.value !== 'WorkspaceName' || _vm.devices.filter(function (x) { return !x.WorkspaceID; }).length > 0; }),"items":_vm.devices,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"padding-left":"10px"},attrs:{"xs":"4"}},[_c('h1',[_c('v-icon',{attrs:{"large":"","color":"black"}},[_vm._v("mdi-gesture-tap-hold")]),_vm._v(" "+_vm._s(_vm.$t('players.totems', _vm.locale)))],1)]),_c('v-flex',{staticStyle:{"padding":"10px"},attrs:{"xs":"6"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"padding-left":"10px"},attrs:{"xs6":""}},[_c('search',{attrs:{"onSearch":_vm.onSearch,"showClear":_vm.searchValue && _vm.searchValue.length > 0 ? true : false,"maxWidth":400}})],1),_c('v-flex',{staticStyle:{"text-align":"right"},attrs:{"xs6":""}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},on:{"click":_vm.onRefresh}},[_vm._v(" "+_vm._s(_vm.$t('common.refresh', _vm.locale))+" ")]),_c('v-menu',{attrs:{"top":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")]),_c('v-icon',[_vm._v("mdi-view-list")])],1)]}}])},[_c('v-list',_vm._l((_vm.viewTypes),function(item,index){return _c('v-list-item',{key:index,on:{"click":function () { _vm.onChangeView(item.id) }}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.Presentation",fn:function(ref){
var item = ref.item;
return [_c('template-presentation',{attrs:{"item":item,"onClick":_vm.handleEditPresentationByID}})]}},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.onEdit(item.ID)}}},[_vm._v(_vm._s(item.Name ? item.Name : item.ID))])]}},{key:"item.LCD",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: item.Seconds > 120 ? 'red' : 'green' })},[_vm._v(" "+_vm._s(_vm.getDate(item.LCD))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }