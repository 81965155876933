<template>
      <div style="background-color: white;">
    <v-data-table
      :headers="headers.filter(x => x.value !== 'WorkspaceName' || devices.filter(x => !x.WorkspaceID).length > 0)"
      :items="devices"
      :items-per-page="-1"
      class="elevation-0"
      hide-default-footer
    >      
      <template 
        v-slot:top 
      >
        <v-layout wrap>
          <v-flex
            xs="4"
            style="padding-left:10px;"
          >
            <h1><v-icon large color="black">mdi-gesture-tap-hold</v-icon> {{$t('players.totems', locale)}}</h1>
          </v-flex>
          <v-flex
            xs="6"
            style="padding:10px;"
          >
            <v-layout wrap>
              <v-flex xs6 style="padding-left:10px;">
                <search :onSearch="onSearch" :showClear="searchValue && searchValue.length > 0 ? true : false" :maxWidth="400" />
              </v-flex>
              <v-flex xs6 style="text-align: right;">
                <v-btn 
                  style="margin-right:20px;"
                  @click="onRefresh"
                >
                  {{ $t('common.refresh', locale) }}
                </v-btn>
                <v-menu
                  top
                  close-on-click
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                      <v-icon>mdi-view-list</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in viewTypes"
                      :key="index"
                      @click="() => { onChangeView(item.id) }"
                    >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:item.Presentation="{ item }">
        <template-presentation
          :item="item"
          :onClick="handleEditPresentationByID"
        />
      </template>
      <template v-slot:item.ident="{ item }">
        <a @click="onEdit(item.ID)">{{item.Name ? item.Name : item.ID }}</a>
      </template>
      <template v-slot:item.LCD="{item}">
        <span
          :style="{ color: item.Seconds > 120 ? 'red' : 'green' }"
        >
          {{getDate(item.LCD)}}
        </span>
      </template>
    </v-data-table>
      </div>
</template>
<script>
import Search from '@/components/Search'
export default {
  components: {
    Search,
  },
  props: {
    viewTypes: {
      type: Array,
      default: null,
    },
    devices: {
      type: Array,
      default: null,
    },
    onChangeView: {
      type: Function,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onRefresh: {
      type: Function,
      required: true,
    },
    onSearch: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    searchValue: {
      type: String,
      default: null,
    },
  },
  computed: {
    headers () {
      return [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'ident',
        },
        { text: 'Cliente', sortable: false, value: 'WorkspaceName' },
        { text: 'Versi�n', sortable: false, value: 'Version' },
        { text: 'URL', sortable: false, value: 'URL' },
        /*
        { text: 'Presentation', align: 'center', sortable: false, value: 'Presentation' },
        { text: 'Time(seg)', sortable: false, value: 'Time' },
        { text: 'APK', align: 'center', sortable: false, value: 'APK' },
        */
        { text: 'LCD', sortable: false, value: 'LCD' },
        { text: 'LCD', sortable: false, value: 'Seconds' },
      ]
    },
  },
  methods: {
    getDate (v) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

      return v.toLocaleDateString('es', options)
    },
  },
}
</script>

